import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { MButton } from '../atoms/Button';

import { BUTTON_THEME } from '../../settings';

const sectionContent = {
  title: `When you can't afford to be wrong`,
  description: `A lack of expertise can make problems worse, leading to extra expenses and unwanted delays. Don't risk trying to resolve it on your own. Use in-depth guidelines, customized to your situation, to make sure you stay on track.`,
  advice: `When you can't afford to be wrong, let the online experts help you get it right.`,
  button: {
    type: 'secondary',
    label: 'Get live expert help now',
  },
};

const WhenYouCanNotAffordToBeWrong = () => {
  const chat = useSelector(state => state.chat);

  const handleClick = () => {
    console.log(sectionContent.button.label);
  };

  const verticalAnimation = {
    hidden: {
      opacity: 0,
      translateY: -20,
    },
    visible: custom => ({
      opacity: 1,
      translateY: 0,
      transition: { delay: custom * 0.4 },
    }),
  };

  return (
    <motion.section
      viewport={{ amount: 0.2, once: true }}
      initial="hidden"
      whileInView="visible"
      className={cn(
        'h-screen w-screen when-you-can-not-afford-to-be-wrong relative',
        chat && 'blur-sm',
      )}
    >
      <div className="w-[calc(100%-32px)] md-sm:w-[calc(100%-48px)] md:w-full xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] mx-auto h-full flex items-end absolute top-1/4 md:static">
        <div className="when-you-can-not-afford-to-be-wrong_content xl:w-[534px] xxl:w-[665px] xxxl:w-[830px] relative bg-wild-sand p-[24px] md-sm:p-[32px] md-sm:pl-[24px] xl:pl-[0] xl:pt-[56px] xl:pr-[20px] xl:pb-[70px] md:top-[1px]">
          <motion.h2
            custom={1}
            variants={verticalAnimation}
            className="text-black max-w-[219px] md-sm:max-w-[361px] md:max-w-full font-manrope text-[24px] font-[700] leading-[32px] md-sm:font-inter md-sm:text-[32px] md-sm:font-[500] md-sm:leading-[40px] l:font-manrope xl:text-[40px] l:font-[700] xl:leading-[50px] mb-[24px]"
          >
            {sectionContent.title}
          </motion.h2>
          <motion.p
            custom={2}
            variants={verticalAnimation}
            className="text-black font-[300] text-[14px] leading-[20px] md-sm:text-[16px] md-sm:leading-[24px] l:leading-[20px] l:tracking-tight-light mb-[16px] md-sm:mb-[24px] md:mb-[16px]"
          >
            {sectionContent.description}
          </motion.p>
          <motion.p
            custom={3}
            variants={verticalAnimation}
            className="text-black max-w-[267px] md-sm:max-w-[377px] md:max-w-full text-[14px] font-[600] leading-[20px] sm-md:text-[16px] md-sm:leading-[24px] l:font-[500] l:leading-[20px] mb-[32px]"
          >
            {sectionContent.advice}
          </motion.p>
          <MButton
            custom={4}
            variants={verticalAnimation}
            onClick={handleClick}
            theme={BUTTON_THEME.SECONDARY}
            className="px-[24px] py-[12px] h-[42px] w-full md-sm:w-[224px] l:w-[216px] ml-[2px] mb-[2px]"
            label={sectionContent.button.label}
          />
        </div>
      </div>
    </motion.section>
  );
};

export default WhenYouCanNotAffordToBeWrong;
