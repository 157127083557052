import { SET_UNIQ_ID } from '../actions/uniqID';
import { v4 as uuidv4 } from 'uuid';

const uniqId = uuidv4();

const uniqIDReducer = (state = null, action) => {
  switch (action.type) {
    case SET_UNIQ_ID:
      return uniqId.substring(0, 8);
    default:
      return state;
  }
};

export default uniqIDReducer;
