import React, { useState, useEffect } from 'react';
import Error500 from '../atoms/Error500';
import AfterCreateTransactionMessage from '../atoms/AfterCreateTransactionMessage';
import CustomerData from '../molecules/CustomerData';
import CreditCardData from '../molecules/CreditCardData';
import CommonLoader from '../atoms/CommonLoader';

const CheckoutSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerID, setCustomerID] = useState(null);
  const [error, setError] = useState(null);
  const [isSuccessfully, setIsSuccessfully] = useState({
    show: false,
    status: null,
  });

  const showSuccessfulMessage = message => {
    setIsSuccessfully({ show: true, status: message });
  };

  useEffect(() => {
    if (customerID) {
      stopLoading();
    }

    if (error || isSuccessfully.show) {
      const timer = setTimeout(() => {
        setError(false);
        setIsSuccessfully({ show: false, status: null });
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccessfully.show]);

  const paymentTitle = `Dear customer, could you please provide us with your personal information?`;

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <section className="w-screen min-h-screen py-[40px] flex items-center justify-center checkout-section relative">
      <div className="bg-black opacity-80 absolute top-0 left-0 w-full h-full z-0" />
      {error ? (
        <Error500 />
      ) : isSuccessfully.show ? (
        <AfterCreateTransactionMessage message={isSuccessfully?.status} />
      ) : (
        <>
          {isLoading ? (
            <CommonLoader />
          ) : (
            <>
              {customerID ? (
                <CreditCardData
                  customerID={customerID}
                  startLoading={startLoading}
                  stopLoading={stopLoading}
                  showSuccessfulMessage={showSuccessfulMessage}
                />
              ) : (
                <CustomerData
                  startLoading={startLoading}
                  stopLoading={stopLoading}
                  setCustomerID={setCustomerID}
                  setError={setError}
                  paymentTitle={paymentTitle}
                />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default CheckoutSection;
