import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import createTransaction from '../../helpers/createTransaction';
import CreditCardMock from '../atoms/CreditCardMock';
import CommonInput from '../atoms/CommonInput';
import Button from '../atoms/Button';
import { BUTTON_THEME } from '../../settings';

const formCardFields = [
  {
    id: 1,
    label: 'CC Number',
    name: 'CCNumber',
    type: 'number',
    max: '19',
  },
  {
    id: 2,
    label: 'Expiry Date',
    name: 'ExpDate',
    type: 'text',
  },
  {
    id: 3,
    label: 'Name On Card',
    name: 'NameOnCard',
    max: '50',
  },
  {
    id: 4,
    label: 'Security Code',
    name: 'CVV',
    type: 'number',
    max: '4',
  },
];

const validationSchemaToCreditCardForm = Yup.object().shape({
  CCNumber: Yup.string().required('Credit Card Number is required'),
  ExpDate: Yup.string()
    .required('Expiry date is required')
    .matches(
      /^(0[1-9]|1[0-2])\/\d{2}$/,
      'Expiry date should be in MM/YY format',
    ),
  NameOnCard: Yup.string().required('Name On Card is required'),
  CVV: Yup.string().required('CVV is required'),
});

const CreditCardData = ({
  customerID,
  startLoading,
  stopLoading,
  showSuccessfulMessage,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const product = searchParams?.get('product');

  const price = product === '2' ? '$34.99' : '$1';
  const productID =
    product === '2'
      ? process.env.REACT_APP_PRODUCT_ID_MONTH
      : process.env.REACT_APP_PRODUCT_ID_TRIAL;

  const idempotencyKey = uuidv4();
  const formikCreditCardData = useFormik({
    initialValues: {
      CCNumber: '',
      ExpDate: '',
      NameOnCard: '',
      CVV: '',
    },
    validationSchema: validationSchemaToCreditCardForm,
    onSubmit: async values => {
      const numbers = values?.ExpDate.split('/');
      const month = parseInt(numbers[0], 10);
      const year = 2000 + parseInt(numbers[1], 10);
      const updatedValues = {
        CCNumber: values?.CCNumber,
        ExpMonth: month,
        ExpYear: year,
        NameOnCard: values.NameOnCard,
        CVV: values.CVV,
      };
      startLoading();
      const message = await createTransaction(
        updatedValues,
        customerID,
        idempotencyKey,
        productID,
      );
      showSuccessfulMessage(message);
      stopLoading();
    },
  });

  const handleInputChange = (e, fieldName, maxValue) => {
    const input = e.target;
    let value = input.value;

    if (fieldName === 'CCNumber' || fieldName === 'CVV') {
      value = value.replace(/\D/g, '');
    }

    if (value.length > maxValue) {
      value = value.substring(0, maxValue);
    }

    formikCreditCardData.setFieldValue(fieldName, value);
  };

  const handleExpiryDateChange = e => {
    const input = e.target;
    let value = input.value;
    value = value.replace(/\D/g, '');

    if (value.length > 2) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    value = value.substring(0, 5);

    input.value = value;
    input.selectionStart = input.selectionEnd = value.length;
    formikCreditCardData.setFieldValue('ExpDate', value);
  };

  return (
    <div className="flex justify-center items-center lg-md:items-start flex-col lg-md:flex-row-reverse gap-[30px] md:gap-[50px] lg:gap-[90px] max-h-full">
      <CreditCardMock values={formikCreditCardData.values} price={price} />
      <form
        onSubmit={formikCreditCardData.handleSubmit}
        className="w-fit flex flex-col"
      >
        {formCardFields.map(field => (
          <div key={field.id} className="relative mb-[20px] md:mb-[10px]">
            <CommonInput
              name={field.name}
              type={field.type}
              label={field.label}
              onChange={
                field.name === 'ExpDate'
                  ? handleExpiryDateChange
                  : e => handleInputChange(e, field.name, field.max)
              }
              onBlur={formikCreditCardData.handleBlur}
              value={formikCreditCardData.values[field.name]}
            />
            {formikCreditCardData.touched[field.name] &&
              formikCreditCardData.errors[field.name] && (
                <div className="text-red-500 absolute text-[12px] top-[calc(100%+2px)] md:top-[calc(100%-12px)] left-0 md:left-[110px] lg:left-[145px] whitespace-nowrap">
                  {formikCreditCardData.errors[field.name]}
                </div>
              )}
          </div>
        ))}
        <Button
          type="submit"
          className="w-[calc(100%-40px)] md-sm:w-[150px] h-[48px] mx-auto text-[16px] leading-[24px] lg:text-[18px] right-0 bottom-[100px] mt-[16px]"
          label="Submit"
          theme={BUTTON_THEME.SECONDARY}
        />
      </form>
    </div>
  );
};

export default CreditCardData;
