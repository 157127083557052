import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

const sectionContent = {
  powered: 'Powered by JustAnswer',
  copy: `© 2018-2023 askanexpertonline.com. All rights reserved.`,
  links: [
    { href: '#', label: 'Privacy Policy' },
    { href: '#', label: 'Terms of Service' },
  ],
};

const Footer = () => {
  const chat = useSelector(state => state.chat);
  return (
    <footer
      className={cn(
        'p-[16px] pl-[0] pt-[248px] md-sm:pt-[234px] md-sm:p-[24px] md-sm:pl-[0] md:pt-[32px] md:py-[32px] md:pl-0 w-[calc(100%-32px)] md-sm:w-[calc(100%-48px)] xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] mx-auto h-full',
        chat && 'blur-sm',
      )}
    >
      <div className="max-w-[325px]">
        <h4 className="text-black-80 text-[12px] leading-[16px] font-[600] md-sm:text-[16px] md-sm:font-[500] md-sm:leading-[20px] l:text-[12px] l:font-[400] l:leading-[16px] mb-[10px] md-sm:mb-[16px] l:mb-[6px]">
          {sectionContent.powered}
        </h4>
        <h5 className="text-black-80 max-w-[230px] md-sm:max-w-full text-[12px] font-[400] leading-[16px] md-sm:text-[14px] md-sm:leading-[18px] l:text-[12px] l:leading-[16px] mb-[32px]">
          {sectionContent.copy}
        </h5>
        <ul className="flex gap-[16px] md-sm:gap-[32px] l:gap-[10px]">
          {sectionContent.links.map(({ href, label }, index) => (
            <a
              key={index}
              href={href}
              target="_blank"
              rel="noreferrer"
              className="text-black text-[12px] font-[500] leading-[16px] md-sm:text-[16px] md-sm:leading-[20px] l:text-[12px] l:leading-[16px]"
            >
              {label}
            </a>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
