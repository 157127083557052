const copyToClipboard = textToCopy => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard: ' + textToCopy);
      })
      .catch(err => {
        console.error('Unable to copy text to clipboard: ' + err);
      });
  } else {
    console.error('Clipboard API not supported in this browser.');
  }
};

export default copyToClipboard;
