import React from 'react';
import Header from '../components/molecules/Header';
import Hero from '../components/organisms/Hero';
import WhatYouGet from '../components/organisms/WhatYouGet';
import WhenYouCanNotAffordToBeWrong from '../components/organisms/WhenYouCanNotAffordToBeWrong';
import Footer from '../components/molecules/Footer';
import Chat from '../components/organisms/Chat';

function HomePage() {
  return (
    <>
      <Header />
      <Hero />
      <WhatYouGet />
      <WhenYouCanNotAffordToBeWrong />
      <Footer />
      <Chat />
    </>
  );
}

export default HomePage;
