import { OPEN_CHAT, CLOSE_CHAT } from '../actions/chat';

const chatReducer = (state = true, action) => {
  switch (action.type) {
    case OPEN_CHAT:
      return true;
    case CLOSE_CHAT:
      return false;
    default:
      return state;
  }
};

export default chatReducer;
