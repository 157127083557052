import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import Chat from '../components/organisms/Chat';
import ExpertSection from '../components/organisms/ExpertSection';
import HeaderExpert from '../components/molecules/HeaderExpert';
import CommonLoader from '../components/atoms/CommonLoader';
import WrongUserCredentials from '../components/atoms/WrongUserCredentials';

import { CHAT_TYPE, expertUserConfig } from '../settings';

const ExpertPage = () => {
  const { loginWithRedirect, isLoading, user } = useAuth0();
  const userAuth0 = useSelector(state => state.userAuth0);

  if (isLoading) {
    return (
      <CommonLoader className="absolute top-0 bottom-0 left-0 right-0 h-screen w-screen flex items-center justify-center" />
    );
  }

  if (!user) {
    loginWithRedirect();
  }

  return (
    <>
      <HeaderExpert />
      <ExpertSection>
        {userAuth0?.email === expertUserConfig?.expert_email ? (
          <Chat type={CHAT_TYPE.EXPERT} />
        ) : (
          <WrongUserCredentials />
        )}
      </ExpertSection>
    </>
  );
};

export default ExpertPage;
