import React from 'react';
import ChatsIcon from '../../assets/chats-icon.svg';

const ButtonShowChat = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="border-[2px] pointer-events-auto border-white w-[72px] h-[72px] md-sm:w-[80px] md-sm:h-[80px] bg-green rounded-full flex xl:hidden items-center justify-center fixed bottom-[30px] right-[30px] cursor-pointer"
    >
      <img src={ChatsIcon} alt="chats icon" />
    </button>
  );
};

export default ButtonShowChat;
