import React from 'react';
import CheckoutSection from '../components/organisms/CheckoutSection';

function CheckoutPage() {
  return (
    <>
      <CheckoutSection />
    </>
  );
}

export default CheckoutPage;
