import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { openChat, closeChat } from '../../redux/actions/chat';
import { MUserChat } from '../molecules/UserChat';
import ExpertChat from '../molecules/ExpertChat';
import ButtonShowChat from '../atoms/ButtonShowChat';
import { CHAT_TYPE } from '../../settings';

const Chat = ({ type = CHAT_TYPE.CLIENT }) => {
  const isMobile = useMediaQuery({ maxWidth: 1280 });
  const dispatch = useDispatch();
  const chat = useSelector(state => state.chat);

  const handleShowChat = () => {
    dispatch(openChat());
  };

  const handleCloseChat = () => {
    dispatch(closeChat());
  };

  useEffect(() => {
    isMobile ? handleShowChat() : handleCloseChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const chatAnimation = {
    hidden: {
      opacity: 0,
      translateY: -40,
    },
    visible: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <>
      {type === CHAT_TYPE.CLIENT ? (
        <motion.section
          initial="hidden"
          whileInView="visible"
          className={cn(
            'fixed top-0 left-0 z-99999 w-[100vw] h-[100vh] xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] xl:h-full xl:top-[calc(50%-50%)] bottom-0 right-0 m-auto xl:left-[141px] flex justify-center xl:justify-end items-center pointer-events-none',
            chat && isMobile && 'bg-black bg-opacity-70',
          )}
        >
          <MUserChat variants={chatAnimation} closeChat={handleCloseChat} />
          {!chat && isMobile && <ButtonShowChat onClick={handleShowChat} />}
        </motion.section>
      ) : (
        <ExpertChat />
      )}
    </>
  );
};

export default Chat;
