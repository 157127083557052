import React, { useState } from 'react';
import Select from '../atoms/Select';
import Button from '../atoms/Button';
import findValueFromArray from '../../helpers/findValueFromArray';
import copyToClipboard from '../../helpers/copyToClipboard';

export const products = [
  { label: 'trial-pay', value: '?product=1', id: 1 },
  { label: 'month-pay', value: '?product=2', id: 2 },
];
const location = window?.location?.protocol;
const hostname = window?.location?.host;
const page = 'checkout';

const CheckoutLinks = () => {
  const [product, setProduct] = useState(null);
  const baseURL = `${location}//${hostname}/${page}${findValueFromArray(
    product,
    products,
  )}`;

  const handleClick = () => {
    copyToClipboard(baseURL);
  };

  console.log(baseURL);
  return (
    <div className="flex justify-center items-center gap-[10px]">
      <Select
        name={'product'}
        label={''}
        onChange={value => setProduct(value)}
        value={product}
        options={products}
        className="min-w-[calc(100%-16px)]"
      />
      <Button
        type="button"
        className="w-fit px-[20px] mb-[10px] md:mb-[20px] h-[36px] text-[16px] leading-[24px] lg:text-[18px]"
        label="Copy"
        onClick={handleClick}
      />
    </div>
  );
};

export default CheckoutLinks;
