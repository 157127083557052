import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions/userAuth0';
import { startLoading, endLoading } from '../../redux/actions/loading';
import Logo from '../atoms/Logo';
import CommonLoader from '../atoms/CommonLoader';
import Button from '../atoms/Button';
import { BUTTON_THEME } from '../../settings';

const HeaderExpert = () => {
  const { loginWithRedirect, logout, user } = useAuth0();
  const isLoading = useSelector(state => state.loading);
  const userAuth0 = useSelector(state => state.userAuth0);

  const dispatch = useDispatch();

  const handleLogOut = () => {
    logout();
    dispatch(loginUser(null));
  };

  useEffect(() => {
    if (user) {
      dispatch(startLoading());
      dispatch(loginUser(user));
      dispatch(endLoading(false));
    }
  }, [dispatch, user]);

  return (
    <header className="flex justify-between items-center w-[calc(100%-48px)] absolute xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] h-[65px] mx-auto left-0 right-0 top-[24px] xxxl:top-[50px] z-[10]">
      <Logo />
      <>
        {isLoading ? (
          <CommonLoader size={10} />
        ) : (
          <>
            {userAuth0 ? (
              <Button
                label="Sign Out"
                theme={BUTTON_THEME.SECONDARY}
                onClick={handleLogOut}
                className="px-[24px] py-[12px] h-[42px]"
              />
            ) : (
              <Button
                label="Sign In"
                theme={BUTTON_THEME.SECONDARY}
                onClick={loginWithRedirect}
                className="px-[24px] py-[12px] h-[42px]"
              />
            )}
          </>
        )}
      </>
    </header>
  );
};

export default HeaderExpert;
