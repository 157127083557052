import React, { forwardRef } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { BUTTON_THEME } from '../../settings';

const Button = forwardRef(
  ({ theme = BUTTON_THEME.MAIN, label, onClick, className }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={cn(
          'rounded-[8px] text-[14px] font-[500] leading-[18px] transition-transform duration-300 transform scale-10 active:scale-[.98]',
          theme === BUTTON_THEME.MAIN
            ? 'bg-green text-white hover:bg-green-dark'
            : 'border border-green text-green bg-transparent',
          className,
        )}
      >
        {label}
      </button>
    );
  },
);

export default Button;
export const MButton = motion(Button);
