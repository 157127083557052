import React, { useState, useRef } from 'react';
import cn from 'classnames';

const Select = ({ label, name, value, onChange, options, className }) => {
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const selectRef = useRef(null);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleOptionClick = selectedValue => {
    onChange(selectedValue);
    toggleMenu();
  };

  return (
    <div
      className={cn(
        'mb-[5px] md:mb-[16px] flex flex-col md:flex-row whitespace-nowrap items-start justify-between md:items-center gap-[5px]',
        className,
      )}
    >
      {label && label !== '' && (
        <label
          htmlFor={name}
          className="block text-[14px] lg:text-[18px] text-left text-white font-semibold whitespace-nowrap"
        >
          {label}
          <span className="hidden md:inline-block">:</span>
        </label>
      )}
      <div className="relative inline-block w-full md:w-[240px]">
        <div
          ref={selectRef}
          onClick={() => {
            setIsSelectFocused(true);
            toggleMenu();
          }}
          onBlur={() => {
            setIsSelectFocused(false);
            toggleMenu();
          }}
          className={cn(
            'shadow w-full h-[30px] border rounded py-1 text-wild-sand leading-tight focus:outline-none focus:shadow-outline cursor-pointer text-left px-3 mb-[5px]',
          )}
        >
          {value}
        </div>
        {isSelectFocused && menuVisible && (
          <ul className="absolute z-10 top-full left-0 w-[101%] bg-black opacity-95 rounded shadow h-[200px] overflow-y-scroll">
            {options.map(state => (
              <li
                key={state.id}
                onClick={() => handleOptionClick(state.label)}
                className={cn(
                  'p-2 cursor-pointer text-left',
                  value === state.label
                    ? 'green-20 text-green border-[1px] border-green'
                    : 'text-wild-sand',
                )}
              >
                {state.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Select;
