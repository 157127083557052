export const Auth0Config = {
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window?.location?.origin,
};

export const expertUserConfig = {
  expert_id: process.env.REACT_APP_EXPERT_ID,
  expert_name: process.env.REACT_APP_EXPERT_NAME,
  expert_email: process.env.REACT_APP_EXPERT_EMAIL,
  expert_photoUrl: process.env.REACT_APP_EXPERT_PHOTO,
  expert_welcomeMessage: `Good Day Sir!
If you have any inquiries or require assistance, please do not hesitate to reach out. We are at your service to provide optimal solutions.
With warm regards,
Your expert John!`,
  expert_role: process.env.REACT_APP_EXPERT_ROLE,
};

export const statesOfUS = [
  { label: 'Alabama', value: 'AL', id: 1 },
  { label: 'Alaska', value: 'AK', id: 2 },
  { label: 'Arizona', value: 'AZ', id: 3 },
  { label: 'Arkansas', value: 'AR', id: 4 },
  { label: 'California', value: 'CA', id: 5 },
  { label: 'Colorado', value: 'CO', id: 6 },
  { label: 'Connecticut', value: 'CT', id: 7 },
  { label: 'Delaware', value: 'DE', id: 8 },
  { label: 'District Of Columbia', value: 'DC', id: 9 },
  { label: 'Florida', value: 'FL', id: 10 },
  { label: 'Georgia', value: 'GA', id: 11 },
  { label: 'Hawaii', value: 'HI', id: 12 },
  { label: 'Idaho', value: 'ID', id: 13 },
  { label: 'Illinois', value: 'IL', id: 14 },
  { label: 'Indiana', value: 'IN', id: 15 },
  { label: 'Iowa', value: 'IA', id: 16 },
  { label: 'Kansas', value: 'KS', id: 17 },
  { label: 'Kentucky', value: 'KY', id: 18 },
  { label: 'Louisiana', value: 'LA', id: 19 },
  { label: 'Maine', value: 'ME', id: 20 },
  { label: 'Maryland', value: 'MD', id: 21 },
  { label: 'Massachusetts', value: 'MA', id: 22 },
  { label: 'Michigan', value: 'MI', id: 23 },
  { label: 'Minnesota', value: 'MN', id: 24 },
  { label: 'Mississippi', value: 'MS', id: 25 },
  { label: 'Missouri', value: 'MO', id: 26 },
  { label: 'Montana', value: 'MT', id: 27 },
  { label: 'Nebraska', value: 'NE', id: 28 },
  { label: 'Nevada', value: 'NV', id: 29 },
  { label: 'New Hampshire', value: 'NH', id: 30 },
  { label: 'New Jersey', value: 'NJ', id: 31 },
  { label: 'New Mexico', value: 'NM', id: 32 },
  { label: 'New York', value: 'NY', id: 33 },
  { label: 'North Carolina', value: 'NC', id: 34 },
  { label: 'North Dakota', value: 'ND', id: 35 },
  { label: 'Ohio', value: 'OH', id: 36 },
  { label: 'Oklahoma', value: 'OK', id: 37 },
  { label: 'Oregon', value: 'OR', id: 38 },
  { label: 'Pennsylvania', value: 'PA', id: 39 },
  { label: 'Rhode Island', value: 'RI', id: 40 },
  { label: 'South Carolina', value: 'SC', id: 41 },
  { label: 'South Dakota', value: 'SD', id: 42 },
  { label: 'Tennessee', value: 'TN', id: 43 },
  { label: 'Texas', value: 'TX', id: 44 },
  { label: 'Utah', value: 'UT', id: 45 },
  { label: 'Vermont', value: 'VT', id: 46 },
  { label: 'Virginia', value: 'VA', id: 47 },
  { label: 'Washington', value: 'WA', id: 48 },
  { label: 'West Virginia', value: 'WV', id: 49 },
  { label: 'Wisconsin', value: 'WI', id: 50 },
  { label: 'Wyoming', value: 'WY', id: 51 },
];

export const CURRENT_MONTH = new Date().getMonth() + 1;
export const CURRENT_YEAR = new Date().getFullYear() % 100;

export const BUTTON_THEME = { MAIN: 'MAIN', SECONDARY: 'SECONDARY' };
export const CHAT_TYPE = { CLIENT: 'CLIENT', EXPERT: 'EXPERT' };

export const SUCCESS = 'SUCCESS';