const findValueFromArray = (labelToFind, array) => {
  const foundState = array.find(state => state.label === labelToFind);
  if (foundState) {
    return foundState.value;
  } else {
    return null;
  }
};

export default findValueFromArray;
