import { Route, Routes } from 'react-router-dom';
import { HomePage, ExpertPage,CheckoutPage  } from './pages';

function App() {
  return (
    <div className="App overflow-hidden relative">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/expert" element={<ExpertPage />} />
        <Route path="/checkout" element={<CheckoutPage />}/>
      </Routes>
    </div>
  );
}

export default App;
