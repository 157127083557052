import React, { useEffect, useState, useRef, useMemo } from 'react';
import Talk from 'talkjs';
import moment from 'moment';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { expertUserConfig } from '../../settings';
import useGetConversations from '../../hooks/useGetConversations';

import arrowIcon from '../../assets/arrow-white.svg';

const ExpertChat = () => {
  const isMobile = useMediaQuery({ maxWidth: 1280 });
  const conversations = useGetConversations();
  const [talkLoaded, markTalkLoaded] = useState(false);
  const [selectedConversationIndex, setSelectedConversationIndex] = useState(0);
  const [conversationListHidden, setConversationListHidden] = useState(false);
  const chatboxEl = useRef();

  const handleConversation = index => {
    setSelectedConversationIndex(index);
  };

  const handleToggleConversationsList = () => {
    isMobile && setConversationListHidden(!conversationListHidden);
  };

  const { expert_id, expert_name, expert_email, expert_photoUrl, expert_role } =
    expertUserConfig;

  useEffect(() => {
    Talk.ready.then(() => {
      markTalkLoaded(true);

      if (talkLoaded && conversations.length > 0) {
        const currentUser = new Talk.User({
          id: expert_id,
          name: expert_name,
          email: expert_email,
          photoUrl: expert_photoUrl,
          role: expert_role,
        });

        const session = new Talk.Session({
          appId: process.env.REACT_APP_ID,
          me: currentUser,
        });

        const conversation = session.getOrCreateConversation(
          conversations[selectedConversationIndex].id,
        );
        const chatbox = session.createChatbox();
        chatbox.select(conversation);
        chatbox.mount(chatboxEl.current);

        return () => session.destroy();
      }
    });
  }, [
    conversations,
    expert_email,
    expert_id,
    expert_name,
    expert_photoUrl,
    expert_role,
    selectedConversationIndex,
    talkLoaded,
  ]);

  const renderConversationListItem = useMemo(
    () => (conversation, index) => {
      const { lastMessage, id } = conversation;
      const { createdAt } = lastMessage;

      return (
        <li
          key={index}
          onClick={() => handleConversation(index)}
          className={cn(
            'text-left pl-[30px] p-[5px] xl:pl-[5px] w-full cursor-pointer flex items-center justify-between gap-[10px] h-[30px]',
            index === selectedConversationIndex
              ? 'bg-green text-white'
              : 'text-black bg-wild-sand hover:text-black-50',
          )}
        >
          <span className="text-[12px] leading-[14px] md:text-[14px] font-[400] md:leading-[18px]">
            {id}
          </span>
          <span className="text-[10px] leading-[10px] w-[50px]">
            {moment(createdAt).format('hh:mm A MM/DD/YY')}
          </span>
        </li>
      );
    },
    [selectedConversationIndex],
  );

  return (
    <div className="flex gap-[5px] w-full flex-start relative">
      {talkLoaded && (
        <>
          {isMobile ? (
            <>
              <div
                className={cn(
                  'h-[70vh] overflow-y-auto absolute left-0 top-0 transition-all duration-500',
                  conversationListHidden
                    ? 'w-full opacity-1'
                    : 'w-[0px] opacity-0',
                )}
              >
                <ul className="bg-wild-sand flex gap-[10px] flex-col h-full">
                  {conversations.length > 0 &&
                    conversations.map((conversation, index) =>
                      renderConversationListItem(conversation, index),
                    )}
                </ul>
              </div>
              <button
                onClick={handleToggleConversationsList}
                className={cn(
                  'absolute h-[40px] w-[40px] bg-green rounded-full border-[2px] border-white flex items-center justify-center top-[calc(50%-12px)] left-[-18px]',
                )}
              >
                <img
                  src={arrowIcon}
                  alt="arrow icon"
                  className={cn(
                    'w-[20px]',
                    conversationListHidden && 'rotate-180',
                  )}
                />
              </button>
            </>
          ) : (
            <div className="h-[70vh] overflow-y-auto w-[300px]">
              <ul className="bg-wild-sand flex gap-[10px] flex-col h-full">
                {conversations.length > 0 &&
                  conversations.map((conversation, index) =>
                    renderConversationListItem(conversation, index),
                  )}
              </ul>
            </div>
          )}
          <div
            className="w-full h-[70vh]"
            style={{ boxShadow: '-1px 6px 10px 0px rgba(0, 0, 0, 0.08)' }}
            ref={chatboxEl}
          />
        </>
      )}
    </div>
  );
};

export default ExpertChat;
