import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { motion } from 'framer-motion';
import Talk from 'talkjs';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { setUniqID } from '../../redux/actions/uniqID';
import { expertUserConfig } from '../../settings';

import closeIcon from '../../assets/close-white.svg';

const UserChat = forwardRef(({ closeChat }, ref) => {
  const isMobile = useMediaQuery({ maxWidth: 1280 });
  const dispatch = useDispatch();
  const uniqID = useSelector(state => state.uniqID);
  const chat = useSelector(state => state.chat);

  if (!uniqID) {
    dispatch(setUniqID());
  }

  const [talkLoaded, markTalkLoaded] = useState(false);
  const chatboxEl = useRef();

  const {
    expert_id,
    expert_name,
    expert_email,
    expert_photoUrl,
    expert_welcomeMessage,
    expert_role,
  } = expertUserConfig;

  useEffect(() => {
    Talk.ready.then(() => markTalkLoaded(true));

    if (talkLoaded) {
      const currentUser = new Talk.User({
        id: uniqID,
        name: `Client-${uniqID}`,
        role: 'client',
      });

      const otherUser = new Talk.User({
        id: expert_id,
        name: expert_name,
        email: expert_email,
        photoUrl: expert_photoUrl,
        welcomeMessage: expert_welcomeMessage,
        role: expert_role,
      });

      const session = new Talk.Session({
        appId: process.env.REACT_APP_ID,
        me: currentUser,
      });

      const conversationId = Talk.oneOnOneId(currentUser, otherUser);
      const conversation = session.getOrCreateConversation(conversationId);
      conversation.setParticipant(currentUser);
      conversation.setParticipant(otherUser);

      const chatbox = session.createChatbox();
      chatbox.select(conversation);
      chatbox.mount(chatboxEl.current);

      return () => session.destroy();
    }
  }, [
    expert_email,
    expert_id,
    expert_name,
    expert_photoUrl,
    expert_role,
    expert_welcomeMessage,
    talkLoaded,
    uniqID,
  ]);

  return (
    <div ref={ref} className="pointer-events-auto">
      {isMobile ? (
        <div
          className={cn(
            'relative transition-opacity duration-500 opacity-1',
            chat ? 'opacity-1' : 'opacity-0',
          )}
        >
          <div
            className="w-[calc(100vw-32px)] md-sm:w-[calc(100wv-48px)] md:w-[537px] xxxl:w-[675px]"
            style={{ boxShadow: '-1px 6px 10px 0px rgba(0, 0, 0, 0.08)' }}
            ref={chatboxEl}
          />
          {isMobile && (
            <button
              onClick={closeChat}
              className="absolute right-[16px] top-[16px] z-[99999]"
            >
              <img src={closeIcon} alt="close icon" />
            </button>
          )}
        </div>
      ) : (
        <div
          className="w-[537px] xxxl:w-[675px]"
          style={{ boxShadow: '-1px 6px 10px 0px rgba(0, 0, 0, 0.08)' }}
          ref={chatboxEl}
        />
      )}
    </div>
  );
});

export default UserChat;
export const MUserChat = motion(UserChat);
