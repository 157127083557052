import React from 'react';
import { useSelector } from 'react-redux';
import Logo from '../atoms/Logo';
import cn from 'classnames';

const Header = () => {
  const chat = useSelector(state => state.chat);

  return (
    <header
      className={cn(
        'w-[calc(100%-48px)] xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] h-[65px] mx-auto left-0 right-0 absolute top-[24px] xxxl:top-[50px] z-[10]',
        chat && 'blur-sm',
      )}
    >
      <Logo />
    </header>
  );
};

export default Header;
