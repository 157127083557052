import React from 'react';
import oopsImg from '../../assets/wrong-user-credentials.png';

function WrongUserCredentials() {
  return (
    <div
      className="w-full h-[70vh] bg-red-500 flex justify-center items-center flex-col relative z-2"
      style={{ boxShadow: '-1px 6px 10px 0px rgba(0, 0, 0, 0.08)' }}
    >
      <img src={oopsImg} alt="oops" className="h-[200px]" />
      <h2 className="text-white text-[40px] font-[700] leading-[48px] md-sm:font-manrope md-sm:text-[48px] md-sm:font-[800] md-sm:leading-[60px] l:text-[40px] l:leading-[48px] xxxl:font-inter xxxl:text-[48px] xxxl:leading-[60px]">
        Permission denied, wrong user credentials!
      </h2>
    </div>
  );
}

export default WrongUserCredentials;
