import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import findValueFromArray from '../../helpers/findValueFromArray';
import useGetIP from '../../hooks/useGetIP';
import createNewCustomer from '../../helpers/createNewCustomer';
import CommonInput from '../atoms/CommonInput';
import Select from '../atoms/Select';
import Button from '../atoms/Button';
import { BUTTON_THEME, statesOfUS } from '../../settings';

const initFormUserFields = [
  {
    id: 1,
    label: 'First Name',
    name: 'FirstName',
  },
  {
    id: 2,
    label: 'Last Name',
    name: 'LastName',
  },
  {
    id: 3,
    label: 'Address 1',
    name: 'Address1',
  },
  {
    id: 4,
    label: 'Address 2',
    name: 'Address2',
  },
  {
    id: 5,
    label: 'City',
    name: 'City',
  },
  {
    id: 6,
    label: 'State',
    name: 'State',
  },
  {
    id: 7,
    label: 'Zipcode',
    name: 'Zipcode',
  },
  {
    id: 8,
    label: 'Country',
    name: 'Country',
  },
  {
    id: 9,
    label: 'Phone',
    name: 'Phone',
  },
  {
    id: 10,
    label: 'Email',
    name: 'Email',
  },
];

const validationSchemaToUserForm = Yup.object().shape({
  FirstName: Yup.string().required('First Name is required'),
  LastName: Yup.string().required('Last Name is required'),
  Address1: Yup.string().required('Address 1 is required'),
  Address2: Yup.string(),
  City: Yup.string().required('City is required'),
  State: Yup.string().required('State is required'),
  Zipcode: Yup.string().required('Zipcode is required'),
  Country: Yup.string().required('Country is required'),
  Phone: Yup.string().required('Phone is required'),
  Email: Yup.string().email('Invalid email').required('Email is required'),
});

const formUserFields = {
  FirstName: '',
  LastName: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Zipcode: '',
  Country: '',
  Phone: '',
  Email: '',
};

const CustomerData = ({
  startLoading,
  stopLoading,
  setCustomerID,
  setError,
  paymentTitle,
}) => {
  const idempotencyKey = uuidv4();
  const IpAddress = useGetIP();

  const formikUserData = useFormik({
    initialValues: formUserFields,
    validationSchema: validationSchemaToUserForm,
    onSubmit: async values => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const updatedValues = {
        SiteID: Number(process.env.REACT_APP_API_SITE_ID),
        SiteName: process.env.REACT_APP_API_SITE_NAME,
        FirstName: values.FirstName?.toUpperCase(),
        LastName: values.LastName?.toUpperCase(),
        Address1: values.Address1,
        Address2: values.Address2,
        City: values.City,
        State: findValueFromArray(values.State, statesOfUS),
        Zipcode: values.Zipcode,
        Country: values.Country,
        Phone: values.Phone,
        Email: values.Email,
        DateEnteredUtc: currentTimestamp,
        StepType: 'Buyer',
        IpAddress: IpAddress,
        IsTest: false,
        IsChargeback: false,
        IsCancelled: false,
        SessionID: null,
      };
      startLoading();
      const customer_id = await createNewCustomer(
        updatedValues,
        idempotencyKey,
      );
      customer_id ? setCustomerID(customer_id) : setError(true);
      stopLoading();
    },
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="max-w-[250px] relative z-2 md:max-w-[480px] lg:max-w-full text-center text-[14px] lg:text-[18px] leading-[22px] lg:leading-[26px] mb-[24px] lg:mb-[40px] text-white font-semibold">
        {paymentTitle}
      </h2>
      <form
        onSubmit={formikUserData.handleSubmit}
        className="flex flex-col gap-[20px] items-center"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[100px] content-center">
          {initFormUserFields.map(field => (
            <div key={field.id} className="relative">
              {field.name === 'State' ? (
                <Select
                  name={field.name}
                  label={field.label}
                  onChange={value =>
                    formikUserData.setFieldValue(field.name, value)
                  }
                  onBlur={formikUserData.handleBlur}
                  value={formikUserData.values[field.name]}
                  options={statesOfUS}
                />
              ) : (
                <CommonInput
                  name={field.name}
                  type="text"
                  label={field.label}
                  onChange={formikUserData.handleChange}
                  onBlur={formikUserData.handleBlur}
                  value={formikUserData.values[field.name]}
                />
              )}
              {formikUserData.touched[field.name] &&
                formikUserData.errors[field.name] && (
                  <div className="text-red-500 absolute text-[28px] top-0 left-[calc(100%+10px)] whitespace-nowrap">
                    *
                  </div>
                )}
            </div>
          ))}
        </div>
        <Button
          theme={BUTTON_THEME.SECONDARY}
          type="submit"
          className="w-full sm:w-[240px] h-[48px] text-[16px] leading-[24px] lg:text-[18px] right-0 bottom-[100px]"
          label="Submit"
        />
      </form>
    </div>
  );
};

export default CustomerData;
