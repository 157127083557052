import { combineReducers } from 'redux';
import userAuth0Reducer from './userAuth0Reducer';
import loadingReducer from './loading';
import uniqIDReducer from './uniqIDReducer';
import chatReducer from './chatReducer';

const rootReducer = combineReducers({
  userAuth0: userAuth0Reducer,
  loading: loadingReducer,
  uniqID: uniqIDReducer,
  chat: chatReducer,
});

export default rootReducer;
