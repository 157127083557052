import React from 'react';
import logo from '../../assets/logo.svg';

function Logo() {
  return (
    <a href="/" className="w-fit">
      <img src={logo} alt="ask an expert website logo" />
    </a>
  );
}

export default Logo;
