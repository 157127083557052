import React from 'react';
import CheckoutLink from '../molecules/CheckoutLinks';

const ExpertSection = ({ children }) => {
  return (
    <main className="expert-section min-h-screen pb-[50px] md:pb-[0] w-screen relative">
      <div className="bg-black opacity-80 absolute top-0 left-0 w-full h-full z-0" />
      <div className="w-[calc(100%-48px)] xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] mx-auto flex flex-col justify-center items-center relative z-99999 pt-[150px]">
        <CheckoutLink />
        {children}
      </div>
    </main>
  );
};

export default ExpertSection;
