import { useEffect, useState } from 'react';
import { arraysIntersection } from '../helpers/arraysIntersection';

const useGetConversations = () => {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const endpointUrl = `https://api.talkjs.com/v1/${process.env.REACT_APP_ID}/conversations`;

        const response = await fetch(endpointUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY}`,
            'Content-type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }

        const data = await response.json();
        const filteredArray = data.data.filter(el => el.lastMessage);
        const uniqConversations = arraysIntersection(
          conversations,
          filteredArray,
        );
        conversations.length > 0
          ? setConversations(prevConversations => [
              ...prevConversations,
              ...uniqConversations,
            ])
          : setConversations(filteredArray);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
    const intervalId = setInterval(fetchConversations, 40000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return conversations;
};

export default useGetConversations;
