import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { motion } from 'framer-motion';
import WhatYouGetCard from '../atoms/WhatYouGetCard';
import card1Image from '../../assets/what-you-get-cards/card-1.png';
import card2Image from '../../assets/what-you-get-cards/card-2.png';
import card3Image from '../../assets/what-you-get-cards/card-3.png';
import card4Image from '../../assets/what-you-get-cards/card-4.png';

const sectionContent = {
  mainTitle: 'What you get:',
  cards: [
    {
      id: '1',
      img: card1Image,
      title: 'Unlimited conversations with Experts',
      description:
        'Connect with a verified professional in minutes. No waiting for appointments. No high fees. Just dependable answers.',
    },
    {
      id: '2',
      img: card2Image,
      title: 'Covering problems in every category',
      description:
        'From complicated problems to quick fixes, our network of qualified experts can answer your questions — now.',
    },
    {
      id: '3',
      img: card3Image,
      title: 'For your entire family',
      description:
        'Share your membership with your partner, parents, and immediate family to stay covered for any problem.',
    },
    {
      id: '4',
      img: card4Image,
      title: 'At any hour',
      description:
        'A team of Experts is on call for you and your family 24/7. Even on holidays.',
    },
  ],
};

const WhatYouGet = () => {
  const chat = useSelector(state => state.chat);

  const animationVariants = index => {
    const animation = {
      hidden: { opacity: 0, translateX: index % 2 === 1 ? 20 : -20 },
      visible: { opacity: 1, translateX: 0 },
    };
    return animation;
  };

  const verticalAnimation = {
    hidden: {
      opacity: 0.7,
      translateY: -40,
    },
    visible: {
      translateY: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <motion.section
      viewport={{ amount: 0.2, once: true }}
      initial="hidden"
      whileInView="visible"
      className={cn(
        'w-[calc(100%-32px)] md-sm:w-[calc(100%-48px)] xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] mx-auto pt-[60px] pb-[60px] l:pb-[85px]',
        chat && 'blur-sm',
      )}
    >
      <motion.h2
        variants={verticalAnimation}
        className="text-black text-[32px] font-[500] leading-[40px] md-sm:font-manrope  md-sm:text-[40px] md-sm:font-[700] md-sm:leading-[50px] mb-[40px] md-sm:mb-[48px]"
      >
        {sectionContent.mainTitle}
      </motion.h2>
      <div className="xl:w-[532px] xxl:w-[665px] xxxl:w-[830px] grid grid-cols-1 md-sm:grid-cols-2 gap-y-[24px] md-sm:gap-y-[32px] md-sm:gap-x-[16px] md:gap-x-[32px] xxxl:gap-y-[40px]">
        {sectionContent.cards.map(({ id, img, title, description }, index) => (
          <WhatYouGetCard
            key={id}
            img={img}
            title={title}
            description={description}
            index={index}
            animationVariants={animationVariants(index)}
          />
        ))}
      </div>
    </motion.section>
  );
};

export default WhatYouGet;
