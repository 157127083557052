import React from 'react';
import { CURRENT_MONTH, CURRENT_YEAR } from '../../settings';

import greenCheckboxCircle from '../../assets/green-checkbox-circle.svg';
import MCLogo from '../../assets/credit-cards/mastercard.svg';
import VisaLogo from '../../assets/credit-cards/visa.svg';
import AMEXLogo from '../../assets/credit-cards/amex.svg';

const mockData = {
  CCNumber: '1234568791011121',
  CVV: '123',
  month: CURRENT_MONTH,
  year: CURRENT_YEAR,
  NameOnCard: 'JOHN SMITH',
};

function CreditCardMock({ values, price }) {
  const { CCNumber, CVV, ExpDate, NameOnCard } = values;
  const numbers = ExpDate.split('/');
  const month = numbers[0];
  const year = numbers[1];

  return (
    <div
      className="w-[250px] h-[130px] sm:w-[315px] sm:h-[185px] rounded-[16px] shadow-2xl relative"
      // eslint-disable-next-line react/style-prop-object
      style={{
        background:
          'linear-gradient(90deg, rgba(0, 197, 8, 0.9) 0%, rgba(10, 14, 18, 1)  100%)',
      }}
    >
      <div className="grid grid-cols-2 h-full w-full p-[5px] sm:p-[15px] gap-[5px]">
        <div className="flex flex-col gap-[4px] text-white text-left text-[14px] font-medium leading-[16px] max-w-fit">
          <span>CC NUMBER</span>
          <span className="text-[12px] leading-[14px] font-[400] rounded-[4px] border-[1px] border-white-80 py-[2px] px-[4px] max-w-fit truncate">
            {CCNumber || mockData.CCNumber}
          </span>
        </div>
        <div className="flex flex-col gap-[4px] text-white text-left text-[14px] font-medium leading-[16px] max-w-fit">
          <span>CVV CODE</span>
          <span className="text-[12px] leading-[14px] font-[400] rounded-[4px] border-[1px] border-white-80 py-[2px] px-[4px] max-w-fit">
            {CVV || mockData.CVV}
          </span>
        </div>
        <div className="flex flex-col gap-[4px] text-white text-left text-[14px] font-medium leading-[16px] max-w-fit">
          <span>EXPIRES</span>
          <div className="flex gap-[5px]">
            <span className="text-[12px] leading-[14px] font-[400] rounded-[4px] border-[1px] border-white-80 py-[2px] px-[4px]">
              {month || mockData.month}
            </span>
            <span className="text-[12px] leading-[14px] font-[400] rounded-[4px] border-[1px] border-white-80 py-[2px] px-[4px]">
              {year || mockData.year}
            </span>
          </div>
        </div>
        <div className="flex gap-[2px] sm:gap-[5px]">
          <img
            src={VisaLogo}
            alt="visa logo"
            className="w-[30px] sm:w-[40px]"
          />
          <img
            src={AMEXLogo}
            alt="am-ex logo"
            className="w-[30px] sm:w-[40px]"
          />
          <img
            src={MCLogo}
            alt="master-card logo"
            className="w-[30px] sm:w-[40px]"
          />
        </div>
        <div className="text-left text-wild-sand text-[14px] font-medium leading-[16px] truncate col-span-1 sm:col-span-2">
          {NameOnCard?.toUpperCase() || mockData.NameOnCard}
        </div>
      </div>
      <div className="price-label w-[150px] h-[40px] flex items-center justify-center gap-[15px] rounded-[8px] shadow-xl absolute left-[calc(100%-120px)] top-[calc(100%-20px)] border-[1px] border-blue bg-black">
        <img
          src={greenCheckboxCircle}
          alt="blue checkbox in circle"
          className="w-[24px]"
        />
        <h4 className="text-white text-[16px] text-semibold leading-[24px] relative z-10">{`PAY ${price}`}</h4>
      </div>
    </div>
  );
}

export default CreditCardMock;
