import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { motion } from 'framer-motion';
import dollarSvg from '../../assets/hero-steps/dollar.svg';
import questionSvg from '../../assets/hero-steps/question.svg';
import chatSvg from '../../assets/hero-steps/chat.svg';

const sectionContent = {
  mainTitle: 'Chat 1-on-1 with an online Expert',
  mainSubTitle: 'Unlimited chats - Join for only $1. Cancel anytime.',
  steps: [
    { id: '1', img: questionSvg, label: 'Describe your question' },
    { id: '2', img: chatSvg, label: 'Connect with experts fast' },
    { id: '3', img: dollarSvg, label: 'Save time and money' },
  ],
};

const textAnimation = {
  hidden: {
    opacity: 0,
    translateX: 100,
  },
  visible: custom => ({
    translateX: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

const bgAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
};

const Hero = () => {
  const chat = useSelector(state => state.chat);
  return (
    <motion.main
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={bgAnimation}
      className={cn(
        'hero-section h-screen w-screen flex items-end relative z-[0]',
        chat && 'blur-sm',
      )}
    >
      <div className="w-[calc(100%-48px)] xl:w-[1024px] xxl:w-[1280px] xxxl:w-[1600px] mx-auto pb-[40px] md-sm:pb-[56px] l:pb-[50px] xxxl:pb-[145px] z-[1]">
        <div className="xl:w-[491px] xxl:w-[614px] xxxl:w-[776px]">
          <motion.h1
            custom={1}
            variants={textAnimation}
            className="text-white text-[40px] font-[700] leading-[48px] md-sm:font-manrope md-sm:text-[48px] md-sm:font-[800] md-sm:leading-[60px] l:text-[40px] l:leading-[48px] xxxl:font-inter xxxl:text-[48px] xxxl:leading-[60px] mb-[16px] xxxl:mb-[24px] max-w-[298px] md-sm:max-w-[463px] md:max-w-full"
          >
            {sectionContent.mainTitle}
          </motion.h1>
          <motion.h2
            custom={2}
            variants={textAnimation}
            className="text-white text-[16px] font-[600] leading-[24px] md-sm:font-manrope md-sm:text-[20px] md-sm:font-[700] md-sm:leading-[30px] xxxl:text-[32px] xxxl:font-[500] xxxl:leading-[40px] xxxl:font-inter mb-[32px] md-sm:mb-[40px] l:mb-[32px] max-w-[298px] md-sm:max-w-full"
          >
            {sectionContent.mainSubTitle}
          </motion.h2>
          <motion.ul
            custom={3}
            variants={textAnimation}
            className="flex flex-col xxxl:flex-row gap-[18px] md-sm:gap-[28px] l:gap-[18px] xxxl:gap[24px]"
          >
            {sectionContent.steps.map(({ id, img, label }) => (
              <li key={id} className="flex gap-[8px] items-center">
                <div className="w-[24px] h-[24px] flex items-center justify-center bg-white-15 rounded-full">
                  <img src={img} alt={label + ' icon'} />
                </div>
                <p className="text-white text-[14px] font-[400] leading-[18px] md-sm:text-[16px] md-sm:font-[500] md-sm:leading-[20px]">
                  {label}
                </p>
              </li>
            ))}
          </motion.ul>
        </div>
      </div>
      <div className="hero-section-gradient absolute bottom-0 left-0 w-full h-full" />
    </motion.main>
  );
};

export default Hero;
