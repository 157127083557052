import React from 'react';
import { motion } from 'framer-motion';

const WhatYouGetCard = ({
  img,
  title,
  description,
  index,
  animationVariants,
}) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={animationVariants}
      transition={{ delay: index * 0.2 }}
      viewport={{ amount: 0.2, once: true }}
      className="xl:maw-w-[244px] xxl:max-w-[306px] xxxl:max-w-[385px] h-full"
    >
      <img
        src={img}
        alt={title + 'image'}
        className="xl:w-[244px] xxl:w-[306px] xxxl:w-[385px] h-auto mb-[16px] l:mb-[12px]"
      />
      <h4 className="text-black text-center text-[16px] font-[700] leading-[24px] md-sm:font-manrope md-sm:capitalize l:normal-case md-sm:text-[20px] md-sm:font-[600] md-sm:leading-[30px] mb-[10px] l:mb-[16px]">
        {title}
      </h4>
      <p className="text-black-50 text-center text-[14px] leading-[18px] md:text-[16px] font-[400] md:leading-[20px]">
        {description}
      </p>
    </motion.div>
  );
};

export default WhatYouGetCard;
